import React from "react";
import Card from "react-bootstrap/Card";
import { BsArrowRightCircle } from "react-icons/bs";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hey, I'm <span className="pink">Damien </span>
            from the<span className="pink"> United States.</span>
            <br />I am an aspiring system administrator and network engineer studying for my Comptia and Cisco certifications. I also code projects for my online community.
            <br />
            <br />
            Apart from servers and programming, some of my other hobbies are:
          </p>
          <ul>
            <li className="about-activity">
              <BsArrowRightCircle /> Playing Video Games
            </li>
            <li className="about-activity">
              <BsArrowRightCircle /> Learning Tech
            </li>
            <li className="about-activity">
              <BsArrowRightCircle /> Reading Books
            </li>
            <li className="about-activity">
              <BsArrowRightCircle /> Computers
            </li>
            <li className="about-activity">
              <BsArrowRightCircle /> Music
            </li>
            <li className="about-activity">
              <BsArrowRightCircle /> Cars
            </li>
          </ul>

          <p style={{ color: "#f5b3bc" }}>
            "To every con, there is a pro!"{" "}
          </p>
          <footer className="blockquote-footer">Zextrah</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
