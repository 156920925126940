import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./projectCards";
import Particle from "../particle";
import yuno from "../../assets/projects/yuno.gif";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Major <strong className="pink">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've spent some time on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={yuno}
              isWebsite={true}
              title="Fallen-Networks"
              description="An online community focused on making servers in numerous games. Most of my hobbyist projects belong to this."
              ghLink="https://fallen-networks.com"
              demoLink="https://fallen-networks.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={yuno}
              isWebsite={false}
              title="Yuno Gasai"
              description="A discord bot written in JavaScript and Node.js, built for discord communities of any size and packed with hundreds of premium features offered for free."
              ghLink="https://github.com/Damien111/YunoGasai"
              demoLink="https://yunogasai.net/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={yuno}
              isWebsite={false}
              title="Kurumi Tokisaki"
              description="A discord bot written in JavaScript and Node.js, contains features privately made for my gaming community I can't otherwise put in Yuno Gasai."
              ghLink="https://github.com/Damien111/KurumiTokisaki"
              demoLink="https://discord.gg/fallennetworks"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={yuno}
              isWebsite={false}
              title="Yuno Gasai-API"
              description="A web api created for my discord bots that pick up slack where other API's fail. Created in PHP and being rewritten in JavaScript/Node.js"
              ghLink="https://github.com/soumyajit4419/https://github.com/Damien111/YunoGasai-API"
              demoLink="https://api.yunogasai.net"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={yuno}
              isWebsite={false}
              title="Gmod Admin Suite"
              description="An LUA administration suite for the SAM Admin Module, supporting IP Bans, per server permissions, extended moderation commands, and more."
              ghLink="https://github.com/Fallen-Networks/GmodAdminSuite"
              // demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={yuno}
              isWebsite={false}
              title="Source Server Status"
              description="A source game server monitoring program written in JavaScript/Node.js that monitors servers for high ping, game updates, server crashes, and more."
              ghLink="https://github.com/Fallen-Networks/SourceServerStatus"
              // demoLink=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
