import React from "react";
import { Col, Row } from "react-bootstrap";

function Certifications() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <img src="https://comptiawebsite.blob.core.windows.net/webcontent/images/default-source/siteicons/logoitfundamentals.svg" className="img-fluid logo" alt="brand" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src="https://comptiawebsite.blob.core.windows.net/webcontent/images/default-source/siteicons/logoaplus.svg" className="img-fluid logo" alt="brand" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src="https://comptiawebsite.blob.core.windows.net/webcontent/images/default-source/siteicons/logonetworkplus.svg" className="img-fluid logo" alt="brand" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src="https://comptiawebsite.blob.core.windows.net/webcontent/images/default-source/siteicons/logosecurityplus.svg" className="img-fluid logo" alt="brand" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src="https://www.freepnglogos.com/uploads/cisco-png-logo/cisco-ccna-png-logo-16.png" className="img-fluid logo" alt="brand" />
      </Col>
    </Row>
  );
}

export default Certifications;
