import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../assets/avatar.png";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn, FaDiscord, FaSteam } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="pink"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I have an extreme passion for system administration and network engineering, with a hobby in programming.
              <br />
              <br />
              My interests include&nbsp;
              <i>
                <b className="pink">building and maitaining servers </b> and
                in areas related to{" "}
                <b className="pink">
                  security and optimization.
                </b>
              </i>
              <br />
              <br />I am also fluent in the languages
              <i>
                <b className="pink"> Javascript, Lua, Python, and PHP</b>
              </i>
              <br />
              <br />
              Whenever possible, I also have a passion for developing small projects
              with <b className="pink">Node.js</b> and
              <i>
                <b className="pink">
                  {" "}
                  other libraries and frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="pink"> React.js, GLua, Cfx, and Oxide</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="pink">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Damien111"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://discord.gg/fallennetworks"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaDiscord />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.steamcommunity.com/id/ripdamien"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaSteam />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/damienmemes"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
